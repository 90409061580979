"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PngFileParser = void 0;
const symbol_sdk_1 = require("symbol-sdk");
const Utils_1 = require("../Utils");
class PngFileParser {
    constructor() {
        this.name = PngFileParser.NAME;
        this.supportedMimeTypes = ['image/png'];
        this.join = Utils_1.Utils.join;
    }
    getMetadata(content) {
        // NOT USED YET, but could be useful to show user friendly metadata to the user.
        const signature = this.getSignatureChunk(content);
        const header = this.getChunk(content, signature.data.length, PngFileParser.TYPE_IHDR);
        const data = header.data.slice(8);
        const width = data.readUInt32BE(0);
        const height = data.readUInt32BE(4);
        const depth = data[8];
        const colorType = data[9]; // bits: 1 palette, 2 color, 4 alpha
        const compr = data[10];
        const filter = data[11];
        const interlace = data[12];
        return {
            width: width,
            height: height,
            depth: depth,
            interlace: Boolean(interlace),
            palette: Boolean(colorType & PngFileParser.COLORTYPE_PALETTE),
            color: Boolean(colorType & PngFileParser.COLORTYPE_COLOR),
            alpha: Boolean(colorType & PngFileParser.COLORTYPE_ALPHA),
            compr: compr,
            filter: filter,
            colorType: colorType,
        };
    }
    getChunk(data, start, expectedType) {
        const chunkSize = data.readUInt32BE(start);
        const type = data.readUInt32BE(start + 4);
        const chunkType = data.slice(start + 4, start + 8);
        let name = '';
        for (let i = 4; i < 8; i++) {
            name += String.fromCharCode(data[start + i]);
        }
        console.log(`png type ${symbol_sdk_1.Convert.uint8ToHex(chunkType)} |  name = ${name} | size = ${chunkSize}`);
        const isValid = PngFileParser.TYPES.find((t) => t == type);
        if (!isValid) {
            throw Error(`Invalid png type ${symbol_sdk_1.Convert.uint8ToHex(chunkType)}`);
        }
        if (expectedType && expectedType != type) {
            throw new Error(`Invalid expected type ${symbol_sdk_1.Convert.uint8ToHex(chunkType)}. Expected DECIMAL ${expectedType} but got ${type}`);
        }
        const end = start + chunkSize + 4 + 4 + 4;
        const chunk = data.slice(start, end);
        return { data: chunk, type, name };
    }
    getSignatureChunk(data) {
        const signature = data.slice(0, 8);
        if (!Utils_1.Utils.arraysEqual(signature, PngFileParser.PNG_SIGNATURE)) {
            throw new Error(`Invalid PNG signature ${symbol_sdk_1.Convert.uint8ToHex(signature)}`);
        }
        return {
            data: signature,
            name: 'signature',
            type: 0,
        };
    }
    getChunks(data) {
        const chunks = [];
        chunks.push(this.getSignatureChunk(data));
        let start = 8;
        while (start < data.length) {
            const chunk = this.getChunk(data, start);
            chunks.push(chunk);
            start += chunk.data.length;
        }
        return chunks;
    }
    split(content) {
        const data = Buffer.from(content);
        const chunks = this.getChunks(data);
        const ihdrChunk = chunks[1];
        const multiLevelChunks = chunks.map((c) => Utils_1.Utils.split(c.data));
        const ihdr = symbol_sdk_1.Convert.uint8ToHex(ihdrChunk.data);
        const header = Object.assign({ [ihdrChunk.name]: ihdr }, this.getMetadata(data));
        return Promise.resolve({
            multiLevelChunks: multiLevelChunks,
            header: header,
        });
    }
}
exports.PngFileParser = PngFileParser;
_a = PngFileParser;
PngFileParser.NAME = 'png';
PngFileParser.COLORTYPE_PALETTE = 1;
PngFileParser.COLORTYPE_COLOR = 2;
PngFileParser.COLORTYPE_ALPHA = 4;
PngFileParser.PNG_SIGNATURE = symbol_sdk_1.Convert.hexToUint8('89504e470d0a1a0a');
PngFileParser.TYPE_IHDR = 0x49484452;
PngFileParser.TYPE_IEND = 0x49454e44;
PngFileParser.TYPE_IDAT = 0x49444154;
PngFileParser.TYPE_PLTE = 0x504c5445;
PngFileParser.TYPE_tRNS = 0x74524e53;
PngFileParser.TYPE_gAMA = 0x67414d41;
PngFileParser.TYPE_pHYs = 0x70485973;
PngFileParser.TYPES = [_a.TYPE_IHDR, _a.TYPE_IDAT, _a.TYPE_IEND, _a.TYPE_PLTE, _a.TYPE_tRNS, _a.TYPE_gAMA, _a.TYPE_pHYs];
