"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
const _ = require("lodash");
class Utils {
    /**
     * It concats a list of Uint8Array into a new one.
     *
     * @param arrays - the Uint8Array to concat.
     */
    static concat(...arrays) {
        const totalLength = arrays.reduce((acc, value) => acc + value.length, 0);
        const result = new Uint8Array(totalLength);
        let length = 0;
        for (const array of arrays) {
            result.set(array, length);
            length += array.length;
        }
        return result;
    }
    static arraysEqual(a, b) {
        if (a === b)
            return true;
        if (a == null || b == null)
            return false;
        if (a.length !== b.length)
            return false;
        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i])
                return false;
        }
        return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    static getMessageFromError(error) {
        let message = error.message || error.toString();
        try {
            if (message) {
                const parsedMessage = JSON.parse(message);
                const parsedBody = JSON.parse(parsedMessage.body);
                message = parsedBody.message || message;
            }
            return message;
        }
        catch (anotherError) {
            return error.toString();
        }
    }
}
exports.Utils = Utils;
Utils.split = (content, maxSize = 1024) => {
    return _.chunk(content, maxSize).map((chuck) => Uint8Array.from(chuck));
};
Utils.join = (chunks) => {
    return Promise.resolve(Utils.concat(..._.flatMap(chunks)));
};
