"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFTService = void 0;
const http = require("http");
const https = require("https");
const operators_1 = require("rxjs/operators");
const symbol_sdk_1 = require("symbol-sdk");
const StorageService_1 = require("./StorageService");
class NFTService {
    constructor(symbolRepositoryFactory, garushRepositoryFactory) {
        this.symbolRepositoryFactory = symbolRepositoryFactory;
        this.garushRepositoryFactory = garushRepositoryFactory;
    }
    //http://ngl-dual-601.symbolblockchain.io:3000/transactions/confirmed?type=16724&transferMosaicId=1D6E695EA6C6EA31&embedded=true
    //http://ngl-dual-601.symbolblockchain.io:3000/transactions/confirmed/4EB724BC80F9368F62BA69E0B48F0A80CAB1069B58DE9E56AA8D6D865BD4BDCA
    //500.000000
    //9500.000000
    //https://nember.art/art?id=1D6E695EA6C6EA31
    //1D6E695EA6C6EA31
    //4EF18BE1687BB030
    async takeNemberArt(mosaicId) {
        const mosaicRepository = this.symbolRepositoryFactory.createMosaicRepository();
        const mosaic = await mosaicRepository.getMosaic(mosaicId).toPromise();
        return { mosaic: mosaic, matadata: await this.getMetadata(mosaic) };
    }
    async getMetadata(mosaic) {
        //TODO create composite id if the metadata if possible.
        const metadataRepository = this.symbolRepositoryFactory.createMetadataRepository();
        const searchedMetadata = await metadataRepository
            .streamer()
            .search({
            metadataType: symbol_sdk_1.MetadataType.Mosaic,
            targetId: mosaic.id,
            targetAddress: mosaic.ownerAddress,
        })
            .pipe((0, operators_1.toArray)())
            .toPromise();
        const finalMetadata = searchedMetadata
            .map((m) => {
            try {
                return JSON.parse(m.metadataEntry.value);
            }
            catch (e) {
                try {
                    return JSON.parse(symbol_sdk_1.Convert.hexToUtf8(m.metadataEntry.value));
                }
                catch (e) {
                    console.error(e);
                    return undefined;
                }
            }
        })
            .find((m) => m !== undefined && m.v === 1);
        if (!finalMetadata) {
            throw new Error(`Cannot find metadata for mosaic ${mosaic.id.toHex()}`);
        }
        return finalMetadata;
    }
    downloadFile(url) {
        return new Promise((resolve, reject) => {
            (url.toLowerCase().startsWith('https:') ? https : http)
                .get(url, (res) => {
                const mime = res.headers['content-type'];
                if (!mime) {
                    return reject(new Error('Mime type cannot be resolved!'));
                }
                else {
                    const buffers = [];
                    res.on('error', (err) => reject(err));
                    res.on('data', (chunk) => {
                        buffers.push(chunk);
                    }).on('end', () => {
                        const buffer = Buffer.concat(buffers);
                        return resolve({ mime, content: buffer });
                    });
                }
            })
                .on('error', (err) => reject(err));
        });
    }
    async createArt(params) {
        const logger = params.logger || new StorageService_1.ConsoleLogger();
        const repositoryFactory = params.garushNetwork ? this.garushRepositoryFactory : this.symbolRepositoryFactory;
        const epochAdjustment = await repositoryFactory.getEpochAdjustment().toPromise();
        const deadline = symbol_sdk_1.Deadline.create(epochAdjustment);
        const networkType = await repositoryFactory.getNetworkType().toPromise();
        const artistAccount = StorageService_1.StorageService.getAccount(params.artistPrivateAccount, networkType);
        const brokerAccount = StorageService_1.StorageService.getAccount(params.brokerPrivateAccount, networkType);
        const flags = symbol_sdk_1.MosaicFlags.create(false, true, false);
        const storageService = new StorageService_1.StorageService(repositoryFactory);
        const storeFileParams = Object.assign(Object.assign({}, params), { logger: logger, signerPrivateAccount: brokerAccount.privateKey, recipientPublicAccount: artistAccount.publicAccount, extraTransactions: [], cosignerAccounts: [], userData: { description: params.description } });
        const mosaicDuration = params.mosaicDuration;
        if (storeFileParams.extraTransactions == undefined) {
            throw new Error('storeFileParams.extraTransactions must not be undefined!');
        }
        if (storeFileParams.cosignerAccounts == undefined) {
            throw new Error('storeFileParams.cosignerAccounts must not be undefined!');
        }
        if (mosaicDuration !== undefined) {
            const nonce = params.nonce || symbol_sdk_1.MosaicNonce.createRandom();
            const mosaicId = symbol_sdk_1.MosaicId.createFromNonce(nonce, brokerAccount.address);
            logger.log(`Creating mosaic ${mosaicId.toHex()} with duration of ${mosaicDuration || 'Infinite'}`);
            const maxMosaicDuration = symbol_sdk_1.UInt64.fromUint(mosaicDuration);
            const mosaicDefinitionTransaction = symbol_sdk_1.MosaicDefinitionTransaction.create(deadline, nonce, mosaicId, flags, 0, maxMosaicDuration, networkType).toAggregate(brokerAccount.publicAccount);
            const mosaicSupplyTransaction = symbol_sdk_1.MosaicSupplyChangeTransaction.create(deadline, mosaicId, symbol_sdk_1.MosaicSupplyChangeAction.Increase, symbol_sdk_1.UInt64.fromUint(1), networkType).toAggregate(brokerAccount.publicAccount);
            storeFileParams.extraTransactions.push(mosaicDefinitionTransaction, mosaicSupplyTransaction);
            storeFileParams.userData = Object.assign(Object.assign({}, storeFileParams.userData), { mosaicId: mosaicId.toHex() });
        }
        if (params.extraTransactions) {
            storeFileParams.extraTransactions.push(...params.extraTransactions);
        }
        if (params.cosignerAccounts) {
            storeFileParams.cosignerAccounts.push(...params.cosignerAccounts);
        }
        return storageService.storeFile(storeFileParams);
    }
    async sellArt(params) {
        var _a, _b;
        const logger = params.logger || new StorageService_1.ConsoleLogger();
        const repositoryFactory = this.symbolRepositoryFactory;
        const { currency } = await repositoryFactory.getCurrencies().toPromise();
        const networkType = await repositoryFactory.getNetworkType().toPromise();
        const nonce = symbol_sdk_1.MosaicNonce.createRandom();
        const artistAccount = StorageService_1.StorageService.getAccount(params.artistPrivateAccount, networkType);
        const buyerAccount = StorageService_1.StorageService.getAccount(params.buyerPrivateAccount, networkType);
        const brokerAccount = StorageService_1.StorageService.getAccount(params.brokerPrivateAccount, networkType);
        const mosaicId = symbol_sdk_1.MosaicId.createFromNonce(nonce, brokerAccount.address);
        const file = await new StorageService_1.StorageService(this.garushRepositoryFactory).loadImageFromHash(params.rootTransactionHash);
        const epochAdjustment = await repositoryFactory.getEpochAdjustment().toPromise();
        const deadline = symbol_sdk_1.Deadline.create(epochAdjustment);
        logger.log(`Creating nft art transfer from broker ${brokerAccount.address.plain()} to buyer ${buyerAccount.address.plain()}. Mosaic ${mosaicId.toHex()}. Fee Multiplier ${params.feeMultiplier}`);
        const nftTransfer = symbol_sdk_1.TransferTransaction.create(deadline, buyerAccount.address, [
            {
                id: mosaicId,
                amount: symbol_sdk_1.UInt64.fromUint(1),
            },
        ], symbol_sdk_1.PlainMessage.create(`Transfer nft art from broker ${brokerAccount.address.plain()} to buyer ${buyerAccount.address.plain()}`), networkType).toAggregate(brokerAccount.publicAccount);
        const percentage = params.percentage;
        logger.log(`Creating ${percentage}% Fee payment from buyer ${buyerAccount.address.plain()} to broker ${brokerAccount.address.plain()}`);
        const feeTransfer = symbol_sdk_1.TransferTransaction.create(deadline, brokerAccount.address, [
            {
                id: currency.unresolvedMosaicId,
                amount: symbol_sdk_1.UInt64.fromUint((params.price.compact() / 100) * percentage),
            },
        ], symbol_sdk_1.PlainMessage.create(`${percentage}% Fee payment from buyer ${buyerAccount.address.plain()} to broker ${brokerAccount.address.plain()}`), networkType).toAggregate(buyerAccount.publicAccount);
        logger.log(`Creating selling price transfer from buyer ${buyerAccount.address.plain()} to to artist ${artistAccount.address.plain()}`);
        const priceTransfer = symbol_sdk_1.TransferTransaction.create(deadline, artistAccount.address, [
            {
                id: currency.unresolvedMosaicId,
                amount: params.price,
            },
        ], symbol_sdk_1.PlainMessage.create(`Selling price payment from buyer ${buyerAccount.address.plain()} to to artist ${artistAccount.address.plain()}`), networkType).toAggregate(buyerAccount.publicAccount);
        return this.createArt(Object.assign(Object.assign({ garushNetwork: false }, params), { nonce: nonce, description: ((_b = (_a = file.metadata.userData) === null || _a === void 0 ? void 0 : _a.description) === null || _b === void 0 ? void 0 : _b.toString()) || '', name: file.metadata.name, content: file.content, mime: file.metadata.mime, extraTransactions: [nftTransfer, feeTransfer, priceTransfer], cosignerAccounts: [buyerAccount] }));
    }
    async resellArt(params) {
        var _a;
        const logger = params.logger || new StorageService_1.ConsoleLogger();
        const repositoryFactory = this.symbolRepositoryFactory;
        const { currency } = await repositoryFactory.getCurrencies().toPromise();
        const networkType = await repositoryFactory.getNetworkType().toPromise();
        const ownerAccount = StorageService_1.StorageService.getAccount(params.ownerPrivateAccount, networkType);
        const buyerAccount = StorageService_1.StorageService.getAccount(params.buyerPrivateAccount, networkType);
        const brokerAccount = StorageService_1.StorageService.getAccount(params.brokerPrivateAccount, networkType);
        const file = await new StorageService_1.StorageService(repositoryFactory).loadMetadataFromHash(params.rootTransactionHash);
        const epochAdjustment = await repositoryFactory.getEpochAdjustment().toPromise();
        const generationHash = await repositoryFactory.getGenerationHash().toPromise();
        const deadline = symbol_sdk_1.Deadline.create(epochAdjustment);
        if (!((_a = file.userData) === null || _a === void 0 ? void 0 : _a.mosaicId)) {
            throw new Error(`There is no mosaic id on file ${params.rootTransactionHash}`);
        }
        const mosaicId = new symbol_sdk_1.MosaicId(file.userData.mosaicId.toString());
        logger.log(`Creating nft art transfer from owner ${ownerAccount.address.plain()} to buyer ${buyerAccount.address.plain()}. Mosaic ${mosaicId.toHex()}. Fee Multiplier ${params.feeMultiplier}`);
        const nftTransfer = symbol_sdk_1.TransferTransaction.create(deadline, buyerAccount.address, [
            {
                id: mosaicId,
                amount: symbol_sdk_1.UInt64.fromUint(1),
            },
        ], symbol_sdk_1.PlainMessage.create(`Transfer nft art from owner ${ownerAccount.address.plain()} to buyer ${buyerAccount.address.plain()}`), networkType).toAggregate(ownerAccount.publicAccount);
        const percentage = params.percentage;
        logger.log(`Creating ${percentage}% Fee payment from buyer ${buyerAccount.address.plain()} to broker ${brokerAccount.address.plain()}`);
        const feeTransfer = symbol_sdk_1.TransferTransaction.create(deadline, brokerAccount.address, [
            {
                id: currency.unresolvedMosaicId,
                amount: symbol_sdk_1.UInt64.fromUint((params.price.compact() / 100) * percentage),
            },
        ], symbol_sdk_1.PlainMessage.create(`${percentage}% Fee payment from buyer ${buyerAccount.address.plain()} to broker ${brokerAccount.address.plain()}`), networkType).toAggregate(buyerAccount.publicAccount);
        logger.log(`Creating selling price transfer from buyer ${buyerAccount.address.plain()} to to owner ${ownerAccount.address.plain()}`);
        const priceTransfer = symbol_sdk_1.TransferTransaction.create(deadline, ownerAccount.address, [
            {
                id: currency.unresolvedMosaicId,
                amount: params.price,
            },
        ], symbol_sdk_1.PlainMessage.create(`Selling price payment from buyer ${buyerAccount.address.plain()} to to artist ${ownerAccount.address.plain()}`), networkType).toAggregate(buyerAccount.publicAccount);
        const aggregate = symbol_sdk_1.AggregateTransaction.createComplete(deadline, [nftTransfer, feeTransfer, priceTransfer], networkType, []).setMaxFeeForAggregate(params.feeMultiplier, 1);
        const storageService = new StorageService_1.StorageService(repositoryFactory);
        await storageService.announceAll([buyerAccount.signTransactionWithCosignatories(aggregate, [ownerAccount], generationHash)], true, logger);
    }
}
exports.NFTService = NFTService;
