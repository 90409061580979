"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericFileParser = void 0;
const _ = require("lodash");
const Utils_1 = require("../Utils");
class GenericFileParser {
    constructor() {
        this.name = GenericFileParser.NAME;
        this.supportedMimeTypes = []; //All
        this.join = Utils_1.Utils.join;
    }
    split(content) {
        const multiLevelChunks = _.chunk(Utils_1.Utils.split(content), 100);
        return Promise.resolve({ multiLevelChunks: multiLevelChunks });
    }
}
exports.GenericFileParser = GenericFileParser;
GenericFileParser.NAME = 'generic';
