"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileParserManager = void 0;
const GenericFileParser_1 = require("./GenericFileParser");
const PngFileParser_1 = require("./PngFileParser");
class FileParserManager {
    constructor() {
        this.fileParsers = [new PngFileParser_1.PngFileParser(), new GenericFileParser_1.GenericFileParser()];
    }
    getFileParser(name) {
        const expectedName = name || GenericFileParser_1.GenericFileParser.NAME;
        const parser = this.fileParsers.find((p) => p.name == expectedName);
        if (!parser) {
            throw new Error(`Cannot find parser with name '${expectedName}'`);
        }
        return parser;
    }
    getFileParserFromMimeType(mimeType) {
        if (!mimeType) {
            return this.getFileParser(undefined);
        }
        const expectedMimeType = mimeType.split(';')[0].toLowerCase();
        const parser = this.fileParsers.find((p) => p.supportedMimeTypes.includes(expectedMimeType));
        return parser || this.getFileParser(undefined);
    }
}
exports.FileParserManager = FileParserManager;
