"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YamlUtils = void 0;
const fs_1 = require("fs");
const yaml = require("js-yaml");
const path_1 = require("path");
class YamlUtils {
    static toYaml(object) {
        return yaml.dump(object, {
            skipInvalid: true,
            indent: 2,
            lineWidth: 200,
            noArrayIndent: true,
            condenseFlow: true,
            noRefs: true,
        });
    }
    static loadFileAsText(fileLocation) {
        return (0, fs_1.readFileSync)(fileLocation, 'utf8');
    }
    static fromYaml(yamlString) {
        return yaml.load(yamlString);
    }
    static loadYaml(fileLocation) {
        return this.fromYaml(this.loadFileAsText(fileLocation));
    }
    static async writeYaml(fileName, object) {
        const yamlString = this.toYaml(object);
        await this.writeTextFile(fileName, yamlString);
    }
    static async writeTextFile(path, text) {
        await this.mkdirParentFolder(path);
        await fs_1.promises.writeFile(path, text, 'utf8');
    }
    static async mkdir(path) {
        await fs_1.promises.mkdir(path, { recursive: true });
    }
    static async mkdirParentFolder(fileName) {
        const parentFolder = (0, path_1.dirname)(fileName);
        if (parentFolder) {
            await this.mkdir(parentFolder);
        }
    }
}
exports.YamlUtils = YamlUtils;
