"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageService = exports.ConsoleLogger = void 0;
const tslib_1 = require("tslib");
const _ = require("lodash");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const symbol_sdk_1 = require("symbol-sdk");
const parser_1 = require("./parser");
const Utils_1 = require("./Utils");
const YamlUtils_1 = require("./YamlUtils");
class ConsoleLogger {
    log(message) {
        console.log(message);
    }
}
exports.ConsoleLogger = ConsoleLogger;
class StorageService {
    constructor(repositoryFactory) {
        this.repositoryFactory = repositoryFactory;
        this.fileParserManager = new parser_1.FileParserManager();
    }
    async storeFile({ signerPrivateAccount, recipientPublicAccount, content, name, mime, feeMultiplier, userData, cosignerAccounts = [], extraTransactions = [], logger = new ConsoleLogger(), }) {
        const epochAdjustment = await this.repositoryFactory.getEpochAdjustment().toPromise();
        const deadline = symbol_sdk_1.Deadline.create(epochAdjustment);
        const generationHash = await this.repositoryFactory.getGenerationHash().toPromise();
        const networkType = await this.repositoryFactory.getNetworkType().toPromise();
        logger === null || logger === void 0 ? void 0 : logger.log(`Splitting file size ${content.length}`);
        const fileMimeType = mime;
        const { parser, multiLevelChunks, header } = await this.split(content, mime, logger);
        const signerAccount = StorageService.getAccount(signerPrivateAccount, networkType);
        const recipientAddress = StorageService.getAddress(recipientPublicAccount, networkType);
        const dataRecipientAddress = signerAccount.address;
        const aggregates = multiLevelChunks.map((chunks) => {
            const innerTransactions = chunks.map((chunk) => {
                const payload = Uint8Array.from(chunk);
                return symbol_sdk_1.TransferTransaction.create(deadline, dataRecipientAddress, [], symbol_sdk_1.RawMessage.create(payload), networkType);
            });
            const aggregate = symbol_sdk_1.AggregateTransaction.createComplete(deadline, innerTransactions.map((t) => t.toAggregate(signerAccount.publicAccount)), networkType, []);
            logger === null || logger === void 0 ? void 0 : logger.log(`Created aggregate with ${innerTransactions.length} transfer transactions`);
            return aggregate.setMaxFeeForAggregate(feeMultiplier, 0);
        });
        logger === null || logger === void 0 ? void 0 : logger.log(`Created ${aggregates.length} data aggregate transactions`);
        const aggregateContent = await this.getContent(aggregates, parser);
        if (!Utils_1.Utils.arraysEqual(aggregateContent, content)) {
            //sanity check
            throw new Error('Invalid aggregate content!');
        }
        const signedTransactions = aggregates.map((aggregate) => signerAccount.sign(aggregate, generationHash));
        const fileMetadata = {
            type: 'garush',
            version: 1,
            name: name,
            size: content.length,
            parser: parser.name,
            mime: fileMimeType,
            hashes: signedTransactions.map((t) => t.hash),
            header: header,
            userData: userData,
        };
        const metadataTransaction = this.createRootTransaction(fileMetadata, deadline, signerAccount.publicAccount, recipientAddress, dataRecipientAddress, networkType, feeMultiplier, extraTransactions, cosignerAccounts === null || cosignerAccounts === void 0 ? void 0 : cosignerAccounts.length, logger);
        const rootTransaction = signerAccount.signTransactionWithCosignatories(metadataTransaction, cosignerAccounts.map((c) => StorageService.getAccount(c, networkType)), generationHash);
        logger === null || logger === void 0 ? void 0 : logger.log(`Root transaction ${rootTransaction.hash} signed`);
        if (true) {
            // For speed, all in parallel
            await this.announceAll([...signedTransactions, rootTransaction], true, logger);
        }
        else {
            await this.announceAll(signedTransactions, true, logger);
            await this.announceAll([rootTransaction], false, logger);
        }
        logger === null || logger === void 0 ? void 0 : logger.log(`All transaction have been confirmed. Use root transaction hash ${rootTransaction.hash} as the file id`);
        return { metadata: fileMetadata, rootTransactionHash: rootTransaction.hash };
    }
    async split(content, mime, logger) {
        const parser = this.fileParserManager.getFileParserFromMimeType(mime);
        try {
            logger === null || logger === void 0 ? void 0 : logger.log(`Parser ${parser.name} resolved from mime type ${mime}`);
            const result = await parser.split(content);
            return Object.assign({ parser }, result);
        }
        catch (e) {
            logger === null || logger === void 0 ? void 0 : logger.log(`Parser ${parser.name} failed to split. Falling back to default parser. Error: ${Utils_1.Utils.getMessageFromError(e)}`);
            const fallbackParser = this.fileParserManager.getFileParser(undefined);
            logger === null || logger === void 0 ? void 0 : logger.log(`Parser ${fallbackParser.name} resolved`);
            const result = await fallbackParser.split(content);
            return Object.assign({ parser: fallbackParser }, result);
        }
    }
    createRootTransaction(fileMetadata, deadline, signerAccount, recipientAddress, dataRecipientAddress, networkType, feeMultiplier, extraTransactions, requiredCosignatures, logger) {
        const { hashes } = fileMetadata, rest = (0, tslib_1.__rest)(fileMetadata, ["hashes"]);
        const metadataWithoutHashes = YamlUtils_1.YamlUtils.toYaml(rest);
        const metadataMessageWithoutHashes = symbol_sdk_1.PlainMessage.create(metadataWithoutHashes);
        const hashesSplit = _.chunk(symbol_sdk_1.Convert.hexToUint8(hashes.join('')), 1024);
        const transferTransaction = symbol_sdk_1.TransferTransaction.create(deadline, recipientAddress, [], metadataMessageWithoutHashes, networkType);
        const innerTransactions = [
            transferTransaction,
            ...hashesSplit.map((hashes) => {
                return symbol_sdk_1.TransferTransaction.create(deadline, dataRecipientAddress, [], symbol_sdk_1.RawMessage.create(Uint8Array.from(hashes)), networkType);
            }),
        ];
        extraTransactions.forEach((t) => {
            logger === null || logger === void 0 ? void 0 : logger.log(`Adding ${t.constructor.name} type ${t.type} added to aggregate transactions`);
        });
        const aggregate = symbol_sdk_1.AggregateTransaction.createComplete(deadline, [...innerTransactions.map((t) => t.toAggregate(signerAccount)), ...extraTransactions], networkType, []);
        logger === null || logger === void 0 ? void 0 : logger.log(`Created aggregate root transaction transaction with ${aggregate.innerTransactions.length} inner transactions`);
        return aggregate.setMaxFeeForAggregate(feeMultiplier, requiredCosignatures);
    }
    async loadImagesMetadata(addressParam) {
        const networkType = await this.repositoryFactory.getNetworkType().toPromise();
        const address = StorageService.getAddress(addressParam, networkType);
        const transactionRepository = this.repositoryFactory.createTransactionRepository();
        return transactionRepository
            .streamer()
            .search({
            group: symbol_sdk_1.TransactionGroup.Confirmed,
            type: [symbol_sdk_1.TransactionType.TRANSFER],
            recipientAddress: address,
            embedded: true,
            order: symbol_sdk_1.Order.Desc,
        })
            .pipe((0, operators_1.mergeMap)((t) => {
            return this.loadTransaction(t, transactionRepository);
        }), (0, operators_1.mergeMap)((t) => {
            const rootTransaction = t;
            const metadata = this.getMetadata(rootTransaction);
            if (!metadata) {
                return rxjs_1.EMPTY;
            }
            else {
                return (0, rxjs_1.of)({ metadata, rootTransaction });
            }
        }), (0, operators_1.toArray)())
            .toPromise();
    }
    loadTransaction(t, transactionRepository) {
        var _a;
        if (t.type != symbol_sdk_1.TransactionType.TRANSFER) {
            throw new Error('Invalid transaction type!');
        }
        const metadata = this.getMetadata(t);
        if (!metadata) {
            return (0, rxjs_1.of)();
        }
        const aggregateHash = (_a = t.transactionInfo) === null || _a === void 0 ? void 0 : _a.aggregateHash;
        if (aggregateHash) {
            return transactionRepository
                .getTransaction(aggregateHash, symbol_sdk_1.TransactionGroup.Confirmed)
                .pipe((0, operators_1.map)((t) => t));
        }
        return (0, rxjs_1.of)(t);
    }
    async loadImageFromHash(transactionHash) {
        const metadata = await this.loadMetadataFromHash(transactionHash);
        return this.loadImageFromMetadata(metadata);
    }
    async loadMetadataFromHash(transactionHash) {
        const transactionRepository = this.repositoryFactory.createTransactionRepository();
        const transactionRoot = (await transactionRepository.getTransaction(transactionHash, symbol_sdk_1.TransactionGroup.Confirmed).toPromise());
        const metadata = this.getMetadata(transactionRoot);
        if (!metadata) {
            throw new Error(`Transaction ${transactionHash} is not a root garush transaction!`);
        }
        return metadata;
    }
    async loadImageFromMetadata(metadata) {
        const fileParser = this.fileParserManager.getFileParser(metadata.parser);
        const transactionRepository = this.repositoryFactory.createTransactionRepository();
        const aggregateTransactions = (await Promise.all(metadata.hashes.map((hash) => {
            return transactionRepository.getTransaction(hash, symbol_sdk_1.TransactionGroup.Confirmed).toPromise();
        }))).map((a) => a);
        const dataTransactionsTotalSize = _.sumBy(aggregateTransactions, (a) => a.size);
        const content = await this.getContent(aggregateTransactions, fileParser);
        return { metadata, content, dataTransactionsTotalSize };
    }
    getContent(aggregateTransactions, fileParser) {
        const chunks = _.map(aggregateTransactions, (aggregate) => aggregate.innerTransactions.map((t) => {
            return t.message.toBuffer();
        }));
        return fileParser.join(chunks);
    }
    getMetadata(transactionRoot) {
        if (transactionRoot.type == symbol_sdk_1.TransactionType.TRANSFER) {
            const transferTransactionRoot = transactionRoot;
            try {
                const metadata = YamlUtils_1.YamlUtils.fromYaml(transferTransactionRoot.message.payload);
                if (!metadata || metadata.type != 'garush') {
                    return undefined;
                }
                return metadata;
            }
            catch (e) {
                console.error('Cannot load metadata!', e);
                return undefined;
            }
        }
        else {
            const aggregateTransactionRoot = transactionRoot;
            try {
                const innerTransactions = aggregateTransactionRoot.innerTransactions;
                const metadata = YamlUtils_1.YamlUtils.fromYaml(innerTransactions[0].message.payload);
                if (!metadata || metadata.type != 'garush') {
                    return undefined;
                }
                const hashes = _.takeWhile(innerTransactions.slice(1), (t) => t.type === symbol_sdk_1.TransactionType.TRANSFER)
                    .map((t) => t.message.toDTO())
                    .join('');
                metadata.hashes = hashes.match(/.{1,64}/g);
                return metadata;
            }
            catch (e) {
                console.error('Cannot load metadata!', e);
                return undefined;
            }
        }
    }
    async announceAll(allSignedTransactions, parallel = false, logger = new ConsoleLogger()) {
        const listener = this.repositoryFactory.createListener();
        const transactionService = new symbol_sdk_1.TransactionService(this.repositoryFactory.createTransactionRepository(), this.repositoryFactory.createReceiptRepository());
        const basicAnnounce = async (signedTransaction) => {
            try {
                listener
                    .status(symbol_sdk_1.PublicAccount.createFromPublicKey(signedTransaction.signerPublicKey, signedTransaction.networkType).address)
                    .subscribe((t) => {
                    logger.log(`There has been an error ${JSON.stringify(t, null, 2)}`);
                });
                logger.log(`Announcing transaction ${signedTransaction.hash}`);
                await transactionService.announce(signedTransaction, listener).toPromise();
                logger.log(`Transaction ${signedTransaction.hash} confirmed`);
            }
            catch (e) {
                console.error(e);
                throw new Error(`Transaction ${signedTransaction.hash} error: ${e}`);
            }
        };
        try {
            await listener.open();
            if (parallel) {
                await Promise.all(allSignedTransactions.map(basicAnnounce));
            }
            else {
                for (const signedTransaction of allSignedTransactions) {
                    await basicAnnounce(signedTransaction);
                }
            }
        }
        finally {
            listener.close();
        }
    }
    static getAddress(publicAccountParam, networkType) {
        if (typeof publicAccountParam === 'string')
            return symbol_sdk_1.Convert.isHexString(publicAccountParam, 64)
                ? symbol_sdk_1.PublicAccount.createFromPublicKey(publicAccountParam, networkType).address
                : symbol_sdk_1.Address.createFromRawAddress(publicAccountParam);
        const address = publicAccountParam.address || publicAccountParam;
        return symbol_sdk_1.Address.createFromRawAddress(address.plain());
    }
    static getPublicAccount(publicAccountParam, networkType) {
        if (typeof publicAccountParam === 'string')
            return symbol_sdk_1.PublicAccount.createFromPublicKey(publicAccountParam, networkType);
        return publicAccountParam;
    }
    static getAccount(privateAccountParam, networkType) {
        if (typeof privateAccountParam === 'string')
            return symbol_sdk_1.Account.createFromPrivateKey(privateAccountParam, networkType);
        return privateAccountParam;
    }
}
exports.StorageService = StorageService;
